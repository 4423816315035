import { ref } from '@vue/composition-api'
import { fbAuth } from '@/plugins/firebase'
import useLogin from './useLogin'
import { useCollection } from '@/composables'
// import utils from '@/utils/utils'
const error = ref(null)
const isPending = ref(null)

const signup = async (email, password, passwordConfirm, displayName) => {
  error.value = null
  isPending.value = true
  try {
    if (password !== passwordConfirm) {
      throw new Error('As senhas não coincidem!')
    }
    const res = await fbAuth.createUserWithEmailAndPassword(email, password)
    if (!res) {
      throw new Error('Impossível se cadastrar.')
    }
    // await utils.delay(3000)
    isPending.value = false
    await res.user.updateProfile({
      displayName,
      photoURL:
        'https://firebasestorage.googleapis.com/v0/b/components-80659.appspot.com/o/default%2Fdefault-profile-icon-16.jpg?alt=media&token=c070e80c-8e6c-44c1-ad79-6263efb1f6dd'
    })
    const { addUser } = useCollection('users')
    await addUser(
      {
        role: {
          admin: false
        },
        coverURLPath: '',
        displayName
      },
      res.user.uid
    )
    await res.user.sendEmailVerification()
    const { login } = useLogin()
    await login(email, password)
    error.value = null
    return res
  } catch (err) {
    isPending.value = false
    console.log(error.value)
    error.value = err.code
  }
}

const useSignup = () => {
  return { error, signup, isPending }
}

export default useSignup
