<template>
  <div class="error--text text-caption text-center pa-4">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Errorform'
}
</script>

<style></style>
