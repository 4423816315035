<template>
  <app-form class="ma-4" @submit.prevent="handleSubmit">
    <v-row>
      <v-col cols="12" class="py-0 my-0 mb-1">
        <text-field-email v-model="email"></text-field-email>
      </v-col>
      <v-col cols="12" class="py-0 my-0 mb-3">
        <text-field-password v-model="password"></text-field-password>
      </v-col>
      <v-col cols="12" class="py-0 my-0 mb-1">
        <app-btn
          dark
          type="submit"
          color="default"
          block
          :loading="isPending"
          rIcon="login"
        >
          Entrar</app-btn
        >
      </v-col>
      <template v-if="loginWithSocial">
        <v-col cols="12"> ou </v-col>

        <v-col class="py-0 my-0 mb-1"
          ><app-btn color="grey" lIcon="google" block @click="handleSubmit"
            >Entrar com Google</app-btn
          ></v-col
        >
        <v-col class="py-0 my-0 mb-1"
          ><app-btn color="primary" lIcon="facebook" block @click="handleSubmit"
            >Entrar com Facebook</app-btn
          ></v-col
        >
      </template>
    </v-row>
    <error-form>{{ error }}</error-form>
  </app-form>
</template>

<script>
import { ref } from '@vue/composition-api'
import { useLogin, useAuthErrors } from '../composables'
import { TextFieldEmail, TextFieldPassword } from '@/components/layout/forms/'
import ErrorForm from './ErrorForm'

export default {
  name: 'LoginForm',

  components: {
    TextFieldPassword,
    TextFieldEmail,
    ErrorForm
  },

  setup(props, { emit }) {
    const email = ref('rrr@test.com')
    const password = ref('123456')
    const loginWithSocial = ref(false)
    const error = ref('')
    const { error: errorLogin, login, isPending } = useLogin()
    const { searchError } = useAuthErrors()

    const handleSubmit = async () => {
      await login(email.value, password.value)
      if (!errorLogin.value) {
        emit('login')
      } else {
        error.value = searchError(errorLogin.value)
      }
    }

    return { error, email, isPending, password, loginWithSocial, handleSubmit }
  }
}
</script>

<style></style>
