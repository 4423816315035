<template>
  <app-form class="ma-4" @submit.prevent="handleSubmit">
    <v-row>
      <v-col cols="12" class="py-0 my-0 mb-1">
        <app-text-field
          v-model="displayName"
          placeholder="Nome"
          icon="account"
        ></app-text-field>
      </v-col>
      <v-col cols="12" class="py-0 my-0 mb-1">
        <text-field-email
          v-model="email"
          placeholder="E-mail"
        ></text-field-email>
      </v-col>
      <v-col cols="12" class="py-0 my-0 mb-3">
        <text-field-password v-model="password"></text-field-password>
      </v-col>
      <v-col cols="12" class="py-0 my-0 mb-3">
        <text-field-password
          v-model="passwordConfirm"
          placeholder="Confirme a senha"
        ></text-field-password>
      </v-col>
      <v-col v-if="1 === 2">
        <app-alert
          :value="error ? true : false"
          :options="'dense'"
          type="error"
        >
          {{ error }}
        </app-alert>
      </v-col>
      <v-col cols="12" class="py-0 my-0 mb-1">
        <app-btn
          dark
          :loading="isPending"
          type="submit"
          color="default"
          block
          rIcon="account-plus"
        >
          Cadastrar</app-btn
        >
      </v-col>
    </v-row>
    <error-form>{{ error }}</error-form>
  </app-form>
</template>

<script>
import { ref } from '@vue/composition-api'
import utils from '@/utils/utils'
import { useSignup, useAuthErrors } from '../composables'
import { TextFieldEmail, TextFieldPassword } from '@/components/layout/forms/'
import ErrorForm from './ErrorForm.vue'
export default {
  name: 'SignupForm',

  components: {
    TextFieldEmail,
    TextFieldPassword,
    ErrorForm
  },

  setup(props, { emit }) {
    const { error: errorSignup, signup, isPending } = useSignup()
    const { searchError } = useAuthErrors()

    const fakeUser = utils.uuid4().substring(0, 7)
    const displayName = ref(fakeUser)
    const email = ref(`${fakeUser}@test.com`)
    const password = ref('123456')
    const passwordConfirm = ref('123456')
    const error = ref('')

    const handleSubmit = async () => {
      await signup(
        email.value,
        password.value,
        passwordConfirm.value,
        displayName.value
      )
      if (!errorSignup.value) {
        emit('signup')
      } else {
        error.value = searchError(errorSignup.value)
      }
    }

    return {
      error,
      isPending,
      displayName,
      email,
      password,
      passwordConfirm,
      handleSubmit
    }
  }
}
</script>

<style></style>
