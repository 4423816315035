import { ref } from '@vue/composition-api'
import { fbAuth } from '@/plugins/firebase'
import store from '@/store'
// import utils from '@/utils/utils'
const error = ref(null)
const isPending = ref(false)

const login = async (email, password) => {
  error.value = null
  isPending.value = true
  try {
    // await utils.delay(3000)
    const res = await fbAuth.signInWithEmailAndPassword(email, password)
    error.value = null
    isPending.value = false
    store.dispatch('auth/setLoggedUser', res.user)
    return res
  } catch (err) {
    isPending.value = false
    store.dispatch('auth/setLoggedUser', null)
    console.log(err.message)
    error.value = err.code
  }
}

const useLogin = () => {
  return { error, isPending, login }
}

export default useLogin
