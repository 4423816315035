<template>
  <app-form class="ma-4" @submit.prevent="handleSubmit">
    <v-row>
      <v-col cols="12" class="py-0 my-0 mb-1">
        <text-field-email v-model="email"></text-field-email>
      </v-col>

      <v-col cols="12" class="py-0 my-0 mb-1">
        <app-btn dark type="submit" color="default" block rIcon="lock-reset">
          Resetar</app-btn
        >
      </v-col>
    </v-row>

    <error-form>{{ error }}</error-form>
  </app-form>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import store from '@/store'
import { useResetPassword, useAuthErrors } from '../composables'
import { TextFieldEmail } from '@/components/layout/forms'
import ErrorForm from './ErrorForm.vue'
export default {
  name: 'ResetPasswordForm',

  components: {
    TextFieldEmail,
    ErrorForm
  },

  setup(props, { emit }) {
    const email = ref('betogroo@gmail.com')
    const { error: errorReset, reset, isPending, success } = useResetPassword()
    const { searchError } = useAuthErrors()
    const error = ref('')

    watch(
      () => isPending.value,
      () => {
        store.dispatch(
          'home/setOverlayLoading',
          !store.state.home.overlayLoading
        )
      }
    )

    const handleSubmit = async () => {
      await reset(email.value)
      if (!errorReset.value) {
        console.log(success)
        emit('reset')
      } else {
        error.value = searchError(errorReset.value)
      }
    }

    return { error, email, handleSubmit, isPending }
  }
}
</script>

<style></style>
