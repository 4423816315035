import { ref } from '@vue/composition-api'
import { fbAuth } from '@/plugins/firebase'

const error = ref(null)
const isPending = ref(null)
const success = ref({})

const reset = async (email) => {
  error.value = null
  isPending.value = true
  try {
    // await fbAuth.signOut()
    const res = await fbAuth.sendPasswordResetEmail(email)
    console.log('Reset Password' + email + ' ' + res)
    success.value = {
      code: 'auth/reset-password-success',
      message: 'Solicitação feita com sucesso'
    }
    error.value = null
    isPending.value = false
  } catch (err) {
    isPending.value = false
    console.log(err.message)
    error.value = err.code
  }
}

const useResetPassword = () => {
  return { reset, error, success, isPending }
}

export default useResetPassword
